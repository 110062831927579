
import { defineComponent, onMounted, inject, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import TableLite from "vue3-table-lite/ts";
import type { Component } from "vue";

// components
// import Badge from "@/components/atoms/Badge.atoms.vue";
import Arrow from "@/components/atoms/Arrow.atoms.vue";
import ButtonDropdown from "@/components/atoms/ButtonDropdown.atoms.vue";
import ResendEmailInvitationModal from "@/components/molecules/ResendEmailInvitationModal.molecules.vue";

// services
import projectService from "@/services/project.service";

// interfaces
import { IProjectDetail, ProjectStatusEnum } from "@/interfaces/project";
import { IParticipant } from "@/interfaces/participant";

// composables
import useProjectStatusBadge from "@/composables/projectStatusBadge";
import useDateFormatter from "@/composables/dateFormatter";
import { AxiosError } from "axios";
import BackButton from "@/components/atoms/BackButton.vue";
import SuccessCreateProjectModalMolecules from "@/components/molecules/SuccessCreateProjectModal.molecules.vue";

export default defineComponent({
  components: {
    "table-lite": TableLite as unknown as Component,
    Arrow,
    ButtonDropdown,
    ResendEmailInvitationModal,
    BackButton,
    SuccessCreateProjectModalMolecules,
    // Badge
  },
  setup() {
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Detail Proyek"));

    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const activeTab = ref<string>("assessment");
    const setActiveTab = (tab: string) => {
      activeTab.value = tab;
    };

    // Init Your table settings
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Email",
          field: "email",
          width: "10%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Tanggal Expired",
          field: "expired_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Jumlah Tes Selesai",
          field: "total_bundle_done",
          width: "15%",
          sortable: true,
        },
        {
          label: "Kapan Ditambah",
          field: "created_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Ditambah Oleh",
          field: "created_by",
          width: "15%",
          sortable: true,
        },
        {
          label: "Aksi",
          field: "action",
          width: "15%",
          sortable: false,
        },
      ],
      rows: [],
      sortable: {
        order: "id",
        sort: "asc",
      },
    });
    // Actions
    const actionView = (id: number) => {
      const projectId = Number(route.params.projectId);

      router.push(`/admin/project/${projectId}/participant/${id}`);
    };

    const showResendEmailModal = ref<null | { show: () => null }>(null);
    const resendEmailParticipantId = ref<number>(0);
    const actionResendEmail = (participantId: number) => {
      resendEmailParticipantId.value = participantId;
      if (showResendEmailModal.value) showResendEmailModal.value.show();
    };

    const showSuccessCreateProjectModal = ref<null | { show: () => null }>(
      null
    );
    const actionShowSuccessCreateProjectModal = () => {
      if (showSuccessCreateProjectModal.value) {
        showSuccessCreateProjectModal.value.show();
      }
    };

    let state = reactive({ projectData: {} as IProjectDetail });

    const getProjectDetail = async () => {
      const projectId = Number(route.params.projectId);

      table.isLoading = true;

      const { data } = await projectService.getProject(projectId);

      state.projectData = data;
      table.isLoading = false;

      table.rows = data.participants.map((d: IParticipant) => {
        d.created_at = formatDateTime(d.created_at, "DD/MM/YYYY");
        d.expired_at = formatDateTime(data.ended_at, "DD/MM/YYYY");

        return d;
      });
    };

    onMounted(async () => {
      await getProjectDetail();

      if (localStorage.getItem("showSuccessProjectModal")) {
        actionShowSuccessCreateProjectModal();
        localStorage.removeItem("showSuccessProjectModal");
      }
    });

    const showStatusDropdown = ref<boolean>(false);
    const toggleStatusDropdown = () => {
      showStatusDropdown.value = !showStatusDropdown.value;
    };

    const isDoneStatus = (status: string) => status === ProjectStatusEnum.Done;

    interface IStatus {
      value: string;
      label: string;
    }
    const selectStatusDropdown = async (val: IStatus) => {
      toggleStatusDropdown();

      if (val.value === state.projectData.status) return;
      if (confirm(`Anda yakin akan mengganti status menjadi ${val.label}?`)) {
        try {
          const projectId = Number(route.params.projectId);

          await projectService.updateProjectStatus(projectId, val.value);
          toast.success("Berhasil mengubah status");
          await getProjectDetail();
        } catch (e) {
          let errMsg =
            (e as AxiosError).response?.data?.error || "Ada kesalahan";
          toast.error(errMsg);
        }
      }
    };

    const showRemoveBtn = (status: ProjectStatusEnum) => {
      const eligibleStatus = [
        ProjectStatusEnum.Draft,
        ProjectStatusEnum.NotStarted,
      ];
      return eligibleStatus.includes(status);
    };

    const showEditBtn = (status: ProjectStatusEnum) => {
      const eligibleStatus = [
        ProjectStatusEnum.Draft,
        ProjectStatusEnum.NotStarted,
        ProjectStatusEnum.OnGoing,
      ];
      return eligibleStatus.includes(status);
    };

    const showAddBtn = (status: ProjectStatusEnum) => {
      const eligibleStatus = [
        ProjectStatusEnum.Draft,
        ProjectStatusEnum.NotStarted,
        ProjectStatusEnum.OnGoing,
      ];
      return eligibleStatus.includes(status);
    };

    const isDraft = (status: ProjectStatusEnum) =>
      status === ProjectStatusEnum.Draft;

    const { getBadgeVariant } = useProjectStatusBadge();
    const { formatDateTime } = useDateFormatter();

    return {
      state,
      activeTab,
      setActiveTab,
      showStatusDropdown,
      toggleStatusDropdown,
      selectStatusDropdown,
      getBadgeVariant,
      isDoneStatus,
      table,
      actionView,
      showResendEmailModal,
      actionResendEmail,
      showSuccessCreateProjectModal,
      resendEmailParticipantId,
      tabs: [
        { id: "assessment", label: "Berdasarkan Asesmen" },
        { id: "participant", label: "Berdasarkan Peserta" },
      ],
      statusDropdownItems: [
        {
          value: ProjectStatusEnum.Draft,
          label: "Draft",
        },
        {
          value: ProjectStatusEnum.NotStarted,
          label: "Belum Mulai",
        },
        {
          value: ProjectStatusEnum.OnGoing,
          label: "Sedang Berjalan",
        },
        {
          value: ProjectStatusEnum.Done,
          label: "Selesai",
        },
      ],
      formatDateTime,
      showRemoveBtn,
      showEditBtn,
      showAddBtn,
      isDraft,
    };
  },
});
